import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import wwfLogo from "../../../content/assets/wwf-logo.svg"
import tiLogo from "../../../content/assets/ti-logo.svg"
import headerImg from "../../../content/assets/header-img.png"
import recommendation1 from "../../../content/assets/recommendation/Ilustra_01.png"
import recommendation2 from "../../../content/assets/recommendation/Ilustra_02.png"
import recommendation3 from "../../../content/assets/recommendation/Ilustra_03.png"
import recommendation4 from "../../../content/assets/recommendation/Ilustra_04.png"
import recommendation5 from "../../../content/assets/recommendation/Ilustra_05.png"

import Menu from "../Menu"

import "./style.css"

const Header = ({ pathname }) => {
  const [pos, setPos] = useState("top")

  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 100) {
        setPos("header-compact")
      } else {
        setPos("header-expanded")
      }
    })
  }, [])

  function headerImage() {
    const headerImag = {
      "/": headerImg,
      "/licitacao-e-contratos/": recommendation1,
      "/licitacao-e-contratos": recommendation1,
      "/lobby-e-conflito-de-interesses/": recommendation2,
      "/lobby-e-conflito-de-interesses": recommendation2,
      "/integridade-publica-e-privada/": recommendation3,
      "/integridade-publica-e-privada": recommendation3,
      "/participacao-e-controle-social/": recommendation4,
      "/participacao-e-controle-social": recommendation4,
      "/gestao-de-impactos-socioambientais/": recommendation5,
      "/gestao-de-impactos-socioambientais": recommendation5,
      "/download": headerImg,
    }

    return headerImag[pathname]
  }

  return (
    <header className="header">
      <div className={"header-fixed " + pos}>
        <Menu />
        <div className="header-contents_logos">
          <p className="subheading-green">Realização</p>
          <Link className="logo-link" to="/">
            <img
              className="logo logo-ti"
              src={tiLogo}
              alt="Transparencia Internacional"
            />
          </Link>
          <Link className="logo-link" to="/">
            <img className="logo logo-wwf" src={wwfLogo} alt="WWF Brasil" />
          </Link>
        </div>
      </div>
      <div className="header-wrapper">
        <div className="header-contents">
          <div className="header-contents_box">
            <p>
              Grandes obras de infraestrutura são consideradas estratégicas para
              o desenvolvimento social e econômico do país. Mas também podem
              significar corrupção e gerar graves impactos socioambientais.
            </p>
          </div>
          <img className="header-img" src={headerImage()} alt="Foto" />
        </div>
        {pathname === '/' &&
        <div className="header-heading">
          <div>
            <h2>
              Grandes obras na Amazônia sem corrupção: um direito do brasileiro
            </h2>
          </div>
          <div className="header-heading_content">
            <p>
              Análise feita pela Transparência Internacional - Brasil e o
              WWF-Brasil mostra mudanças que o Brasil precisa implementar para
              prevenir grandes obras de infraestrutura de ilegalidades e
              proteger a floresta e as comunidades locais de impactos
              socioambientais negativos.
            </p>
          </div>
        </div>
        }
      </div>
    </header>
  )
}

export default Header
