import React from "react"

import wwfLogo from '../../../content/assets/wwf-logo-white.jpg'
import tiLogo from '../../../content/assets/ti-logo-white.svg'

import './styles.css'

const Footer = () => (
	<footer className="footer">
		<div className="footer--logos">
			<h4 className="subheading--green__whitetext">Realização</h4>
			<img className="footer-logo" src={tiLogo} alt="Transparencia Internacional Brasil" />
		</div>
		<div className="footer--items footer--items--logo">
			<img className="footer-logo" src={wwfLogo} alt="WWF Brasil" />
		</div>
		<div className="footer--items">
			<p>O conteúdo deste site e o relatório <span>"Grandes obras na Amazônia, corrupção e impactos socioambientais"</span> são fruto de uma parceria entre a Transparência Internacional - Brasil e o WWF-Brasil. Exceto quando assinalado de outra forma, esses materiais são distribuídos sob uma licença <a href="https://creativecommons.org/licenses/by-sa/4.0/legalcode.pt">Creative Commons do tipo BY-SA 4.0</a>. Aviso de cookies: para entendermos como o conteúdo deste site é consumido por nossos visitantes utilizamos ferramentas de análise de tráfego que podem registrar um cookie em seu computador. Para detalhes de como tratamos os dados gerados durante sua navegação, por favor acesse nossa <a href="https://privacidade.transparenciainternacional.org.br/">Política de Privacidade</a>.</p>
		</div>
	</footer>
)

export default Footer
