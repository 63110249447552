/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title, og_description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metatitle = title || site.siteMetadata?.title
  og_description =
    og_description || description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metatitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content:
            "Grandes obras na Amazônia, corrupção e impactos socioambientais",
        },
        {
          property: `og:description`,
          content: `Grandes obras de infraestrutura são apresentadas por governos e setor privado como necessárias para o desenvolvimento social e econômico do Brasil, sobretudo da Amazônia. Ao mesmo tempo, muitas vezes acabam se tornando alvo de corrupção e vetores para problemas socioambientais.\n
          Como impedir que esse histórico continue se repetindo no futuro? No relatório “Grandes Obras na Amazônia, corrupção e impactos socioambientais”, WWF-Brasil e Transparência Internacional – Brasil apresentam propostas de reformas e boas práticas que podem fazer com que o desenvolvimento econômico caminhe lado a lado com a luta contra a corrupção e a responsabilidade socioambiental.\n
          https://bit.ly/3hV2teR`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
