import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link } from "gatsby"
import "./style.css"

const Menu = () => {
	const [open, setOpen] = useState(false);
	const node = useRef();
	
	const toggleMenu = useCallback(() => {
		setOpen(!open);
	}, [open]);

	const clickOutOfMenu = useCallback((e) => {
		if ( null == node.current ) {
			return;
		}
		
		if ( node.current.contains(e.target) ) {
			// inside click
			return;
		}

		if ( open ) {
			toggleMenu();
		}
	}, [toggleMenu, open]);

	useEffect(() => {
		document.addEventListener("mousedown", clickOutOfMenu);
	}, [clickOutOfMenu]);

	return (
		<nav className="main-navigation" ref={node}>
			<button onClick={toggleMenu} className="menu-toggler" type="button" data-toggle={open} data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
			</button>
			<div id="main-menu" className={`menu-collapse ${open ? "show" : "collapse"}`} data-toggle={open}>
				<button onClick={toggleMenu} className="menu-close" type="button" data-toggle={open} data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Close navigation">
					X
			    </button>
				<ul className="menu">
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/">
							Página principal
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/licitacao-e-contratos">
							Combater a corrupção em licitações e contratos
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/lobby-e-conflito-de-interesses">
							Regulamentar o lobby e o conflito de interesses
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/integridade-publica-e-privada">
							Promover a integridade nos setores público e privado
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/participacao-e-controle-social">
							Fortalecer a participação e o controle social
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/gestao-de-impactos-socioambientais">
							Aprimorar a gestão de impactos socioambientais
						</Link>
					</li>
					<li className="menu-item">
						<Link onClick={toggleMenu} className="menu-link" to="/download">
							Acesse o relatório completo
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	)
}
export default Menu
